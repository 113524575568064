<template>
  <div class="p-12 text-center">
    <p class="text-lg font-medium">Update Required</p>
    <p>To view this screen you need to update and refresh your application.</p>
  </div>
</template>

<script>
export default {
  name: 'Embed',

  mounted() {
    // eslint-disable-next-line
    _chatlio.hide()
  }
}
</script>

<style lang="scss" scoped></style>
